import React from "react";
import DashboardHeader from "../components/DashboardHeader";

const SettingsComp = () => {
  return (
    <>
      <div className="dashboard-body">
        <DashboardHeader title="Paramettres" breadcrumbs="Paramettre" />
      </div>
    </>
  );
};

export default SettingsComp;
